<template>
	<edit :id="id" :project-id="projectId"></edit>
</template>

<script>
	import edit from './contract_edit.vue'
	
	export default {
		name: 'contract_create',
		
		components: {
			edit
		},
		
		props: {
			id: String,
			projectId: String
		}
	};
</script>

<style lang="stylus" scoped>
</style>